export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [3,4];

export const dictionary = {
		"/": [5],
		"/events": [~6],
		"/events/create": [~10],
		"/events/[id]": [7,[2]],
		"/events/[id]/registrations": [9,[2]],
		"/events/[id]/[info]": [~8,[2]],
		"/payments": [~11],
		"/registration-forms/create": [~13],
		"/registration-forms/[id]": [12,[3]],
		"/registrations/create": [~15],
		"/registrations/[id]": [14,[4]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';